<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="ZTVersion">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(0)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>
      </template>
      <table-column prop="companyName" label="公司"></table-column>
      <table-column prop="platformShortName" label="平台简称" :width="100" align="center"></table-column>
      <table-column prop="key" label="唯一标识" :width="100" align="center"></table-column>
      <table-column prop="openUrl" label="Open地址"></table-column>
      <table-column prop="serverUrl" label="服务器地址"></table-column>
      <table-column prop="monitorHost" label="监控服务器地址"></table-column>
      <table-column prop="monitorPort" label="监控服务器端口号" :width="140" align="center"></table-column>
      <table-column prop="dtuServerHost" label="DTU服务器地址"></table-column>
      <table-column prop="dtuServerPort" label="DTU服务器端口号" :width="140" align="center"></table-column>
      <table-column v-if="editAuth" :label="$t('common.operation')" :width="100" align="center" fixed="right" :tooltip="false">
        <template #default="scope">
          <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete")
          }}</el-button>
        </template>
      </table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList($event ? 1 : currentPage)"></edit-dialog>
  </div>
</template>
<script>
import EditDialog from "./ZTVersionEdit.vue";

const moduleName = "ZTVersion";

export default {
  components: { EditDialog },
  data() {
    return {
      editAuth: true,
      loading: false,
      search: {
        filter: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageIndex) {
      this.$refs.vmTable.getList(pageIndex);
    },
    handleSearch(event) {
      this.filter = event;
      this.getList(1);
    },
    handleAdd() {
      this.$refs.editPage.open(0);
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + "公司名" + " " + row.companyName + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById(moduleName, row.id).then(res => {
          this.getList(1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
