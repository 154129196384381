<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="tenant">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" style="width: 150px"
            clearable></el-input>
        </vm-search>
        <vm-search :label="$t('tenant.tenantStatus')">
          <el-select v-model="search.status" :placeholder="$t('common.pleaseSelect')" style="width: 120px" clearable>
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(0)">
          {{ $t("elevatorNetApply.add") }}
        </el-button>
      </template>
      <table-column prop="name" :label="$t('tenant.tenantName')"></table-column>

      <table-column prop="status" :label="$t('tenant.tenantStatus')" align="center" :width="100">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.status === 1">
            {{ $t("tenant.freeze") }}
          </el-tag>
          <el-tag type="success" v-if="scope.row.status === 0">
            {{ $t("deviceGateway.normal") }}
          </el-tag>
        </template>
      </table-column>
      <table-column prop="companyName" :label="$t('tenant.company')"></table-column>
      <table-column prop="elevCount" :label="$t('company.elevCount')" align="center" :width="80"></table-column>
      <table-column prop="userCount" :label="$t('company.userCount')" align="center" :width="80"></table-column>
      <table-column prop="username" :label="$t('device.userName')" :width="140" align="center"></table-column>
      <table-column prop="contact" :label="$t('useUnit.person')" :width="140" align="center"></table-column>
      <table-column prop="phone" :label="$t('user.phone')" align="center" :width="120"></table-column>
      <table-column prop="createTime" :label="$t('tenant.createTime')" align="center"
        :width="140"></table-column>
      <table-column v-if="false" prop="updateTime" :label="$t('tenant.updateTime')"
        align="center"></table-column>
      <table-column :label="$t('elevatorNetApply.operate')" align="center" :width="150" :tooltip="false">
        <template #default="scope">
          <el-button type="text" @click="login(scope.row)">
            {{ $t("login.login") }}
          </el-button>
          <el-button type="text" @click="$refs.editPage.open(scope.row.id)">
            {{ $t("elevatorNetApply.edit") }}
          </el-button>
          <el-button v-if="false" type="text" @click="handleDelete(scope.row)">{{ $t("elevatorNetApply.delete") }}</el-button>
          <el-button type="text" @click="changePwd(scope.row.username)">{{ $t("home.changePassword") }}</el-button>
        </template>
      </table-column>
    </vm-table>
    <tenant-edit ref="editPage" @save-success="getList(-1)"></tenant-edit>
    <tenant-change-pwd ref="changePwd"></tenant-change-pwd>
  </div>
</template>

<script>
import TenantEdit from "@/views/tenant/TenantEdit";
import TenantChangePwd from "@/views/tenant/TenantChangePwd";
import auth from "@/util/auth";

export default {
  components: { TenantEdit, TenantChangePwd },
  data() {
    return {
      loading: true,
      search: {
        filter: "",
        status: "",
      },
      statusList: [
        { value: 1, label: "冻结" },
        { value: 0, label: "正常" },
      ],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleDelete(row) {
      this.$confirm("确定删除吗?", this.$t("common.tips"), { type: "warning" }).then(() => {
        this.$http.delete("tenant", row.id).then(() => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        });
      });
    },
    changePwd(username) {
      this.$refs.changePwd.open(username);
    },
    login(row) {
      let params = {
        username: row.username,
        password: row.password,
      };
      this.$api.getData("account/tenantLogin", params).then((res) => {
        auth.logout();
        auth.login(res.data);
        window.location.href = "/";
      }).catch(error => {
        this.$message.error("登陆失败");
      });
    },
  },
};
</script>

<style scoped></style>
