<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" multi-selection="true" url="tpLink/listAdmin"
              @selection-change="handleSelectionChange">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('tpLink.deviceStatus', '设备状态')">
          <el-select v-model.trim="search.deviceStatus" :placeholder="$t('common.pleaseSelect')" style="width: 100%"
                     clearable>
            <el-option v-for="item in deviceStatusList" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button @click="$refs.setCompany.open()" @save-success="getList(-1)">
          {{$t('tenant.belongUnit')}}
        </el-button>
        <el-button @click="$refs.setTenant.open()" @save-success="getList(-1)">
          {{$t('tenant.belongTenant')}}
        </el-button>
        <el-button v-if="showXsd" @click="$refs.setPlatform.open()" @save-success="getList(-1)">
          所属平台
        </el-button>
        <el-button v-if="false" class="addBtn" @click="$refs.editPage.open()">
          {{$l("elevatorNetApply.add", "+ 新增")}}
        </el-button>
        <el-button v-if="userName === 'superAdmin'" :loading="refreshLoading" @click="refresh">
          {{$l("tpLink.synchronization", "同步VMS")}}
        </el-button>
        <el-button v-if="userName === 'superAdmin' && showXsd" @click="$refs.setUrl.open()">
          {{$l("tpLink.alarmSetting", "报警转发设置")}}
        </el-button>
      </template>
      <table-column prop="platformName" label="所属平台"></table-column>
      <table-column prop="tenantName" label="租户"></table-column>
      <table-column prop="orgName" :label="$t('tenant.belongUnit')"></table-column>
      <table-column prop="devId" :label="$l('tpLink.deviceName', 'VMSID')" :width="80"
                       align="center"></table-column>
      <table-column prop="deviceName" :label="$l('tpLink.deviceName', '监控设备名称')"></table-column>
      <table-column prop="elevatorNo" :label="$l('tpLink.boundElevator', '电梯编号')">
        <template #default="scope">
          <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">
            {{scope.row.elevatorNo}}
          </router-link>
        </template>
      </table-column>
      <table-column prop="elevatorName" :label="$l('tpLink.boundElevator', '已绑定电梯')">
      </table-column>
      <table-column prop="deviceStatus" :label="$l('tpLink.deviceStatus', '设备状态')" :width="100" align="center">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.deviceStatus === 1">{{$t("monitor.online")}}</el-tag>
          <el-tag type="info" v-else>{{$t("monitor.offline")}}</el-tag>
        </template>
      </table-column>
      <table-column prop="deviceType" :label="$l('tpLink.deviceType', '设备类型')" align="center"
                       :width="100"></table-column>
      <table-column prop="deviceModel" :label="$l('tpLink.deviceModel', '设备型号')" :width="140"></table-column>
      <table-column prop="ethernet" :label="$l('elevatorLcd.lcdId', 'MAC地址')" :width="140"></table-column>
      <table-column :label="$l('elevatorNetApply.operate', '操作')" align="center" :width="200" :tooltip="false">
        <template #default="scope">
          <el-button type="text" @click="getFlv(scope.row.devId)">
            {{$l("tpLink.preview", "flv")}}
          </el-button>
          <el-button type="text" :disabled="!scope.row.devId" @click="preview(scope.row.devId)">
            {{$l("tpLink.preview", "预览")}}
          </el-button>
          <el-button v-if="false && siteName === 'G-Cloud电梯云'" type="text"
                     @click="$refs.videoAlarm.open(scope.row.devId)">
            {{$l("tpLink.alarmRecord", "报警")}}
          </el-button>
          <el-button v-if="false && siteName === 'G-Cloud电梯云'" type="text"
                     @click="$refs.videoPeople.open(scope.row.devId)">
            {{$l("tpLink.alarmRecord", "人数")}}
          </el-button>

<!--          <el-button v-if="!scope.row.showInPlatform" type="text"-->
<!--                     @click="showInPlatform(scope.row.id,scope.row.devId,true)">-->
<!--            展示-->
<!--          </el-button>-->
<!--          <el-button v-if="scope.row.showInPlatform && !scope.row.elevatorId" type="text"-->
<!--                     @click="showInPlatform(scope.row.id,scope.row.devId,false)">-->
<!--            不展示-->
<!--          </el-button>-->
          <el-button type="text" @click="showPlayBack(scope.row.devId)">
            {{$l("tpLink.playBack", "回放")}}
          </el-button>
        </template>
      </table-column>
    </vm-table>
    <t-p-link-flv-url ref="flvUrl"></t-p-link-flv-url>
    <t-p-link-play-back ref="playBack"></t-p-link-play-back>
    <tp-link-video-edit ref="editPage" @save-success="getList(-1)"></tp-link-video-edit>
    <video-alarm ref="videoAlarm"></video-alarm>
    <video-people ref="videoPeople"></video-people>
    <set-server-url ref="setUrl"></set-server-url>
    <set-company :multi-select="multiSelect" action-type="tplink" ref="setCompany"
                 @save-success="getList(-1)"></set-company>
    <set-tenant :multi-select="multiSelect" action-type="tplink" ref="setTenant"
                @save-success="getList(-1)"></set-tenant>
    <set-platform :multi-select="multiSelect" action-type="tplink" ref="setPlatform"
    @save-success="getList(-1)"></set-platform>

  </div>
</template>
<script>
  import SetPlatform from "@/views/tenant/SetPlatform";
  import VideoPeople from "@/views/vmVideo/TPLinkVideoPeople";
  import TpLinkVideoEdit from "@/views/tenant/tplink/TPLinkVideoEdit";
  import VideoAlarm from "@/views/vmVideo/TPLinkVideoAlarm";
  import SetServerUrl from "@/views/tenant/tplink/SetServerUrl";
  import SetTenant from "@/views/tenant/SetTenant";
  import SetCompany from "@/views/tenant/SetCompany";
  import TPLinkFlvUrl from "@/views/tenant/tplink/TPLinkFlvUrl";
  import TPLinkPlayBack from "@/views/tenant/tplink/TPLinkPlayBack";
  import {Base64} from "js-base64";
  import auth from "@/util/auth";

  export default {
    components: {TpLinkVideoEdit, TPLinkFlvUrl, TPLinkPlayBack,VideoAlarm, SetServerUrl,SetPlatform, VideoPeople, SetTenant, SetCompany},
    data() {
      return {
        showXsd: auth.getPlatform() === "step" || auth.getPlatform() === "xsd",
        userName: auth.getUsername(),
        loading: true,
        refreshLoading: false,
        siteName: window.config.siteName,
        multiSelect: [],
        search: {
          filter: "",
          deviceStatus: "",
        },
        deviceStatusList: [
          {value: 1, label: this.$l("monitor.online", "在线")},
          {value: 0, label: this.$l("monitor.offline", "离线")},
        ],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getFlv(devId) {
        console.log(devId);
        this.$http.get("tpLink/getFlv/" + devId).then(res => {
          this.$refs.flvUrl.open(res.data);
        }).catch(err => {
          this.$message.success(this.$t("common.tip.operationSuccess"));
        });
      },
      showPlayBack(devId){
        this.$refs.playBack.open(devId);
      },

      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      refresh() {
        this.refreshLoading = true;
        this.$http.get("tpLink/refresh").then(res => {
          this.refreshLoading = false;
          this.getList(1);
          this.$message.success(this.$l("tpLink.synchronizationSucceeded", "同步成功"));
        }).catch(err => {
          this.refreshLoading = false;
          this.$message.error(this.$l("tpLink.synchronizationFailed", "同步失败"));
        });
      },
      preview(devId) {
        this.$http.get("tpLink/getPreviewUrl").then(res => {
          window.open(res.data + "?devId=" + Base64.encode(devId.toString()));
        });
      },
      handleSelectionChange(val) {
        this.multiSelect = val;
      },
      showInPlatform(id, devId, mark) {
        console.log(mark);
        let msg = mark ? "devId:" + devId + "设置平台展示" : "devId:" + devId + "设置平台不展示";
        this.$confirm(msg, this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$http.post("/tpLink/showInPlatform/" + id + "/" + mark).then(res => {
            this.getList(1);
          }).catch((error) => {
            this.getList(1);
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.wrapper {
  margin: 0 10px;
}
</style>
