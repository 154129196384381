<template>
  <el-dialog
    append-to-body
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="$l('tpLink.alarmSetting', '报警转发设置')"
    width="500px"
    top="10vh"
    @close="$reset('form')">
    <el-form
      ref="formValidate"
      label-width="85px"
      :model="urlModel">
      <div class="vm-separate">
        <el-form-item :label="$l('tpLink.url', '转发地址')" prop="url" :rules="$rule.notNull">
          <el-input v-model="urlModel.url" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('tpLink.path', '转发路径')" prop="path" :rules="$rule.notNull">
          <el-input v-model="urlModel.path" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "取消")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$l("elevatorNetApply.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        urlModel:{
          url: "",
          path:""
        }
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        this.$axios.post("tpLink/getUrl").then((res) => {
          this.urlModel = res.data;
        });
      },
      handleSubmit() {
        this.submitLoading = true;
        this.$axios.post("tpLink/setUrl?url=" + this.urlModel.url+"&path="+this.urlModel.path)
          .then(() => {
            this.submitLoading = false;
            this.dialogVisible = false;
            this.$emit("save-success");
            this.$message.success(this.$t("common.tip.settingSuccess"));
          })
          .catch(() => {
            this.submitLoading = false;
          });
      },
    },
  };
</script>

<style scoped>

</style>
